import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function VamosDigital() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c9_4e942b52e1.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Vamos Digital
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                The last on the list{" "}
                <b>top 10 UI UX design companies in Chennai</b>, Vamos Digital,
                became a fully stacked UI UX design agency
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $50-$99 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 2-9
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.vamosdigital.in/" target="_blank">
                  https://www.vamosdigital.in/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | 224/1 Defence Colony, 2nd Main, Bengaluru,
                  Karnataka, India 560038
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                The last on the list{" "}
                <b>top 10 UI UX design companies in Chennai</b>, Vamos Digital,
                became a fully stacked UI UX design agency in just four years
                under the leadership of Creative Director and Founder Pooja
                Goyal. The team at Vamos Digital has adopted an ROI driven
                approach, and the entire team has a collective experience of
                over eight years. The team likes taking up challenges that
                reflect in their portfolio. The team has gathered experience
                working in various sectors, from entertainment to retail, to
                social to finance. The company works with startups as well as
                established brands like Zee Entertainment, StarTv, Lavazza,
                Coffee Klay, Huga Ice Creams, etc. The company has earned a
                feature on The Hindu and Your Story and has multiple awards for
                branding and launching creative digital products.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
